import React, { useContext } from 'react'
import { AccountContext } from '../../../../auth/AuthProvider'

const SidebarHeader = ({unprotected=false, resources, title, children}) => {
    const {hasAtLeastOnePermission} = useContext(AccountContext)
    
    if(unprotected || hasAtLeastOnePermission(resources)) {
        return (
            <ul>
                <li>
                    <div className="mb-3.5">
                        <span className="text-primary font-bold text-base">{title}</span>
                    </div>
                    <ul className="flex flex-col gap-y-4 justify-center">
                        {children}
                    </ul>
                </li>
            </ul>
        )
    }
}

export default SidebarHeader