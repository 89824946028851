import React from 'react'
import { NavLink } from 'react-router-dom'

const NavItem = ({link, children}) => {
  return (
    <NavLink 
      to={link} style={{ textDecoration: "none" }} 
      className="h-fit text-black border-b border-soft-grey hover:bg-[#F9F9F9]"
      // className={({ isActive }) => isActive ? "h-fit text-primary font-semibold border-b border-soft-grey": 'h-fit text-black border-b border-soft-grey'}
    >
        {children}
    </NavLink>
  )
}

export default NavItem