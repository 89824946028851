import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import AuthProvider from './auth/AuthProvider';
import AuthGuard from './auth/AuthGuard';
import LoginGuard from './auth/LoginGuard';

const Login = React.lazy(() => import("./pages/Login"));
// const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
// const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

const Customers = React.lazy(() => import("./pages/Customers"));
const CustomerDetails = React.lazy(() => import("./pages/Customers/CustomerDetails"));

const Vendors = React.lazy(() => import("./pages/Vendors"));
const VendorDetails = React.lazy(() => import("./pages/Vendors/VendorDetails"));

const Projects = React.lazy(() => import("./pages/Projects"));
const ProjectDetails = React.lazy(() => import("./pages/Projects/ProjectDetails"));

const ProjectFinances = React.lazy(() => import("./pages/ProjectFinances"));
const ProjectFinanceDetails = React.lazy(() => import("./pages/ProjectFinances/ProjectFinanceDetails"));

// const ProjectPayments = React.lazy(() => import("./pages/ProjectPayments"));
// const ProjectPaymentDetails = React.lazy(() => import("./pages/ProjectPayments/ProjectPaymentDetails"));
// const ProjectPaymentCostSelection = React.lazy(() => import("./pages/ProjectPayments/ProjectPaymentDetails/ProjectPaymentCostSelection"));

const Employees = React.lazy(() => import("./pages/Employees"));
const EmployeeDetails = React.lazy(() => import("./pages/Employees/EmployeeDetails"));

// const Quotations = React.lazy(() => import("./pages/Quotations"));
// const QuotationDetails = React.lazy(() => import("./pages/Quotations/QuotationDetails"));

const Invoices = React.lazy(() => import("./pages/Invoices"))
const InvoiceDetails = React.lazy(() => import("./pages/Invoices/InvoiceDetails"))
// const InvoiceCostSelection = React.lazy(() => import("./pages/Invoices/InvoiceDetails/InvoiceCostSelection"))

const IncomingPayments = React.lazy(() => import("./pages/IncomingPayments"));
const IncomingPaymentDetails = React.lazy(() => import("./pages/IncomingPayments/IncomingPaymentDetails"))
// const IncomingPaymentRevenueSelection = React.lazy(() => import("./pages/IncomingPayments/IncomingPaymentDetails/IncomingPaymentRevenueSelection"))

// const OutgoingPayments = React.lazy(() => import("./pages/OutgoingPayments"));
// const OutgoingPaymentDetails = React.lazy(() => import("./pages/OutgoingPayments/OutgoingPaymentDetails"))

// // ACCOUNTING
// const Journal = React.lazy(() => import("./pages/Accounting/Journal"));

const Profile = React.lazy(() => import("./pages/Profile"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"))

function App() {
    const location = useLocation()
    return (
        <Routes location={location} key={location.pathname}>
            <Route element={<AuthGuard />}>
                <Route exact path="/" element={<Layout />}>
                    <Route
                        index
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Dashboard />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/customers"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Customers />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/customers/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <CustomerDetails />
                        </React.Suspense>
                        }
                    />

                    
                    {/* VENDORS */}
                    <Route
                        path="/vendors"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Vendors />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/vendors/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <VendorDetails />
                        </React.Suspense>
                        }
                    />

                    {/* EMPLOYEES */}
                    <Route
                        path="/employees"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Employees />
                        </React.Suspense>
                        }
                    />
                    
                    <Route
                        index
                        path="/employees/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <EmployeeDetails />
                        </React.Suspense>
                        }
                    />
                    
                    {/* PROJECTS */}
                    <Route
                        index
                        path="/projects"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Projects />
                        </React.Suspense>
                        }
                    />
                    <Route
                        index
                        path="/projects/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ProjectDetails />
                        </React.Suspense>
                        }
                    />

                    <Route
                        index
                        path="/project-finances"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ProjectFinances />
                        </React.Suspense>
                        }
                    />
                    <Route
                        index
                        path="/project-finances/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ProjectFinanceDetails />
                        </React.Suspense>
                        }
                    />
                    
                    <Route
                        index
                        path="/invoices"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Invoices />
                        </React.Suspense>
                        }
                    />

                    <Route
                        index
                        path="/invoices/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <InvoiceDetails />
                        </React.Suspense>
                        }
                    />
                    

                    <Route
                        index
                        path="/incoming-payments"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <IncomingPayments />
                        </React.Suspense>
                        }
                    />
                    <Route
                        index
                        path="/incoming-payments/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <IncomingPaymentDetails />
                        </React.Suspense>
                        }
                    />
                    {/* 
                    <Route
                        index
                        path="/project-payments"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ProjectPayments />
                        </React.Suspense>
                        }
                    />

                    <Route
                        index
                        path="/project-payments/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ProjectPaymentDetails />
                        </React.Suspense>
                        }
                    />

                    <Route
                        index
                        path="/project-payments/:code/:company_code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ProjectPaymentCostSelection />
                        </React.Suspense>
                        }
                    />

                    <Route
                        index
                        path="/invoices/:code/:company_code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <InvoiceCostSelection />
                        </React.Suspense>
                        }
                    />
                    <Route
                        index
                        path="/incoming-payments/:code/:invoice_number"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <IncomingPaymentRevenueSelection />
                        </React.Suspense>
                        }
                    />

                    <Route
                        index
                        path="/outgoing-payments"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <OutgoingPayments />
                        </React.Suspense>
                        }
                    />
                    
                    <Route
                        index
                        path="/outgoing-payments/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <OutgoingPaymentDetails />
                        </React.Suspense>
                        }
                    /> */}
                    {/* ACCOUNTING */}
                    {/* <Route
                        index
                        path="/journal"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Journal />
                        </React.Suspense>
                        }
                    /> */}




                    {/* MARKETING */}
                    {/* <Route
                        index
                        path="/quotations"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Quotations />
                        </React.Suspense>
                        }
                    />
                    <Route
                        index
                        path="/quotations/:code"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <QuotationDetails />
                        </React.Suspense>
                        }
                    /> */}
                    <Route
                        index
                        path="/profile"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <Profile />
                        </React.Suspense>
                        }
                    />
                    <Route
                        index
                        path="/change-password"
                        element={
                        <React.Suspense fallback={<div>...</div>}>
                            <ChangePassword />
                        </React.Suspense>
                        }
                    />
                </Route>
            </Route>

            {/* Public Routes */}
            <Route element={<LoginGuard />}>

                <Route exact path="/login" element={
                    <React.Suspense fallback={<div>...</div>}>
                        <Login />
                    </React.Suspense>
                }/>
            </Route>
        </Routes>
    );
}

export default App;
