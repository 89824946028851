import { useContext, useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AccountContext } from "./AuthProvider";

const LoginGuard = () => {
    const { getUserToken } = useContext(AccountContext)

    const [firstLoad, setFirstLoad] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [userToken, setUserToken] = useState(null)
    const location = useLocation();

    function loadUser() {
        setLoaded(false)
        setUserToken(getUserToken())
        setFirstLoad(false)
        setLoaded(true)
    }

    useEffect(() => {
        if(firstLoad) {
            loadUser()
        }
        else {
            setLoaded(true)
        }
    }, [userToken])


    return (
        loaded ? 
            !userToken ? <Outlet /> : <Navigate to="/" replace />
            
            :

            <div className="h-screen">
                
            </div>
    );
}

export default LoginGuard;