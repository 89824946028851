import React, { useContext, useState } from 'react'
import SidebarHeader from './SidebarHeader'
import SidebarMenu from './SidebarMenu'
import {AnimatePresence, motion} from 'framer-motion'
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { AccountContext } from '../../../auth/AuthProvider';

const Sidebar = ({sidebarVisible, setSidebarVisible, sidebarMobileVisible, setSidebarMobileVisible}) => {
  
    const { width, height } = useWindowDimensions();


    return (
        <>
            {
                sidebarMobileVisible && (
                <AnimatePresence>
                <motion.div className="lg:hidden z-40 top-0 left-0 w-full h-full fixed bg-modal-backdrop"
                    animate={{
                    opacity: width < 1024 && (!sidebarMobileVisible ? 0 : 1)
                    }}
                    onClick={() => setSidebarMobileVisible(false)}
                >

                </motion.div>
                </AnimatePresence>
                )
            }
            <motion.div className="w-72 min-w-[18rem] fixed top-0 left-0 lg:relative h-screen flex flex-col z-50"
                animate={{
                left: `${ width >= 1024 ? (!sidebarVisible ? '-18rem' : '0px') : (!sidebarMobileVisible ? '-18rem' : '0px')}`,
                }}
                transition="easeOut"
            >
                <div className="h-[4.25rem] w-72 min-w-[18rem] lg:w-full bg-white border-b border-r border-soft-grey flex justify-center items-center">
                    ESACREATIVES
                </div>
                <div className="flex-1 w-72 min-w-[18rem] lg:w-full overflow-y-auto sidebar border-r-soft-grey border-r">
                <nav className="w-full sidebar-content overflow-x-hidden py-6 px-7 flex flex-col gap-y-5 min-h-full bg-white max-h-full">
                    <SidebarHeader title="Dashboard" unprotected={true} resources={[]}>
                        <SidebarMenu unprotected={true} resources={[]} image="/icons/sidebar/dashboard.svg" link="/" onClick={() => setSidebarMobileVisible(false)}>Dashboard</SidebarMenu>
                    </SidebarHeader>

                    <SidebarHeader title="Master Data" resources={["customer"]}>
                        <SidebarMenu resource="customer" image="/icons/sidebar/customers.svg" link="/customers" onClick={() => setSidebarMobileVisible(false)}>Customers</SidebarMenu>
                        <SidebarMenu resource="customer" image="/icons/sidebar/vendors.svg" link="/vendors" onClick={() => setSidebarMobileVisible(false)}>Vendors</SidebarMenu>
                        <SidebarMenu resource="customer" image="/icons/sidebar/employees.svg" link="/employees" onClick={() => setSidebarMobileVisible(false)}>Employees</SidebarMenu>
                        <SidebarMenu resource="customer" image="/icons/sidebar/users.svg" link="/users" onClick={() => setSidebarMobileVisible(false)}>Users</SidebarMenu>
                    </SidebarHeader>

                    <SidebarHeader title="Human Resources" resources={["customer"]}>
                        <SidebarMenu resource="customer" image="/icons/sidebar/employees.svg" link="/employees" onClick={() => setSidebarMobileVisible(false)}>Employees</SidebarMenu>
                        <SidebarMenu resource="customer" image="/icons/sidebar/salary.svg" link="/salaries" onClick={() => setSidebarMobileVisible(false)}>Salary</SidebarMenu>
                    </SidebarHeader>

                    
                    <SidebarHeader title="Project Management" resources={["project", "project-finance"]}>
                        <SidebarMenu resource="project" image="/icons/sidebar/project.svg" link="/projects" onClick={() => setSidebarMobileVisible(false)}>Projects</SidebarMenu>
                        <SidebarMenu resource="project-finance" image="/icons/sidebar/project-finance.svg" link="/project-finances" onClick={() => setSidebarMobileVisible(false)}>Project Finances</SidebarMenu>
                        <SidebarMenu resource="quotation" image="/icons/sidebar/quotation.svg" link="/quotations" onClick={() => setSidebarMobileVisible(false)}>Quotations</SidebarMenu>
                        <SidebarMenu resource="invoicing" image="/icons/sidebar/invoicing.svg" link="/invoices" onClick={() => setSidebarMobileVisible(false)}>Invoices</SidebarMenu>
                        <SidebarMenu resource="vendor-invoicing" image="/icons/sidebar/vendor-invoicing.svg" link="/vendor-invoices" onClick={() => setSidebarMobileVisible(false)}>Vendor Invoices</SidebarMenu>
                    </SidebarHeader>
                    
                    <SidebarHeader title="Purchasing" resources={["purchasing"]}>
                        <SidebarMenu resource="customer" image="/icons/sidebar/incoming-payments.svg" link="/incoming-payments" onClick={() => setSidebarMobileVisible(false)}>Asset Purchase</SidebarMenu>
                        <SidebarMenu resource="customer" image="/icons/sidebar/outgoing-payments.svg" link="/outgoing-payments" onClick={() => setSidebarMobileVisible(false)}>Outgoing Payments</SidebarMenu>
                    </SidebarHeader>

                    <SidebarHeader title="Payments" resources={["purchasing"]}>
                        <SidebarMenu resource="customer" image="/icons/sidebar/incoming-payments.svg" link="/incoming-payments" onClick={() => setSidebarMobileVisible(false)}>Incoming Payments</SidebarMenu>
                        <SidebarMenu resource="customer" image="/icons/sidebar/outgoing-payments.svg" link="/outgoing-payments" onClick={() => setSidebarMobileVisible(false)}>Outgoing Payments</SidebarMenu>
                    </SidebarHeader>
                    
                    {/* 
                    <SidebarHeader title="Accounting">
                        <SidebarMenu image="/icons/sidebar/journal.svg" link="/journal" onClick={() => setSidebarMobileVisible(false)}>Journal</SidebarMenu>
                    </SidebarHeader> */}
                </nav>
                </div>
            </motion.div>
        </>
    )
}

export default Sidebar