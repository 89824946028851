import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const AccountContext = createContext()



const AuthProvider = ({children}) => {
    const [userProfile, setUserProfile] = useState(null)
    const [userData, setUserData] = useState(null)
    const [permissions, setPermissions] = useState(null)
    const navigate = useNavigate();


    async function login(email, password) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
            });


            const res = await response.json()
            if(res.success) {
                setUserData(res.user)
                setPermissions(res.permissions)
                document.cookie = `token=${res.token}; path=/`;
                document.cookie = `user=${JSON.stringify(res.user)}; path=/`;
                document.cookie = `permissions=${JSON.stringify(res.permissions)}; path=/`;
                return {
                    success: true
                }
            }
            else {
                return {
                    success: false,
                    message: res.message
                }
            }
        } catch (error) {
            throw new Error('Failed to fetch data');
        }
    }

    async function logout() {
        try {
            var token = null
            const cookies = document.cookie.split('; ');
        
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                if (cookie.startsWith('token=')) {
                    token = cookie.split('=')[1];
                }
            }

            if(token) {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                });

                document.cookie.split(";").forEach((cookie) => {
                    document.cookie = cookie.replace(/^ +/, "")
                                            .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
                })
            }

            navigate('/login')


        } catch (error) {
            throw new Error('Failed to fetch data');
        }
    }

    function getUserToken() {
        const cookies = document.cookie.split('; ');
        for (var i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            if (cookie.startsWith('token=')) {
                if(cookie.split('=')[1] === 'undefined') {
                    return null
                }
                return cookie.split('=')[1];
            }
        }

        return null
    
    }

    async function getUserData() {
        const cookies = document.cookie.split('; ');
        console.log(cookies)
        for (var i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            if (cookie.startsWith('user=')) {
                setUserData(JSON.parse(cookie.split('=')[1]))
            }
            if (cookie.startsWith('permissions=')) {
                setPermissions(JSON.parse(cookie.split('=')[1]))
            }
        }
    }

    const getUserProfile = async () => {
        // try {
        //     setUserProfile(await Auth.currentAuthenticatedUser())
        // }
        // catch(err) {
        //     setUserProfile(null)
        // }
    }

    const hasPermission = (resource, action = null) => {
        if(permissions) {
            if(permissions.includes('admin:admin')) return true
            if (action) {
                return permissions.includes(`${resource}:${action}`);
            }
            return permissions.some(permission => permission.startsWith(`${resource}:`));
        }
        return false
    };
    
    const hasAtLeastOnePermission = (resources) => {
        if(permissions) {
            if(permissions.includes('admin:admin')) return true
            return resources.some(resource =>
                permissions.some(permission => permission.startsWith(`${resource}:`))
            );
        }
        return false
    };

    useEffect(() => {
        getUserData()
    }, [])


      
    const value = useMemo(() => ({userProfile, getUserProfile, userData, permissions, hasPermission, hasAtLeastOnePermission, getUserData, login, logout, getUserToken}), [userProfile, getUserProfile, userData, permissions, hasPermission, hasAtLeastOnePermission, getUserData, login, logout, getUserToken])
    
    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
        
    )
}

export default AuthProvider