import React, { useState } from 'react'
import Sidebar from './Sidebar'
import { Outlet } from "react-router-dom"
import Topbar from './Topbar'
import { motion } from 'framer-motion'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const Layout = () => {
  const { width, height } = useWindowDimensions();
  const [sidebarVisible, setSidebarVisible] = useState(true)
  const [sidebarMobileVisible, setSidebarMobileVisible] = useState(false)
  
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.4
  }; 



    return (
        <div className="w-screen h-screen overflow-hidden relative">
            <Sidebar sidebarVisible={sidebarVisible} sidebarMobileVisible={sidebarMobileVisible} setSidebarMobileVisible={setSidebarMobileVisible} setSidebarVisible={setSidebarVisible} />
            <motion.div className={`flex flex-col absolute top-0 right-0 bottom-0`}
                initial={{left: `${ width >= 1024 ? (sidebarVisible ? '18rem' : '0px') : '0px'}`}}
                animate={{
                left: `${ width >= 1024 ? (sidebarVisible ? '18rem' : '0px') : '0px'}`
                }}
                transition="easeOut"
            >
                <Topbar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} setSidebarMobileVisible={setSidebarMobileVisible}/>

                <div className="flex-grow flex-shrink bg-[#F6F6F6]  bg-big-logo bg-contain bg-no-repeat bg-center relative">
                <motion.div 
                    className="absolute top-0 left-0 right-0 bottom-0 py-10 px-8 main-container" 
                    initial="initial"
                    animate="in"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <Outlet />
                </motion.div>
                </div>
            </motion.div>
        </div>
    )
}

export default Layout