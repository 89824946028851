import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AccountContext } from '../../../../auth/AuthProvider'

const SidebarMenu = ({unprotected=false, resource, image, link, onClick, children}) => {
    const {hasPermission} = useContext(AccountContext)
    
    if(unprotected || hasPermission(resource)) {
        return (
            <li className="flex flex-row gap-x-6 text-space-grey group" onClick={onClick}>
                <img src={image} alt="" className="w-6 h-6 object-contain group-hover:scale-110" />
                <NavLink to={link} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? "group-hover:scale-110 font-bold": 'group-hover:scale-110'}>
                    {children}
                </NavLink>
            </li>
        )
    }
}

export default SidebarMenu